import $ from 'jquery'
import { setupApp } from './common/setup-app/setup-app'
import { createEventBus } from './common/setup-app/event-bus'

window.constants = require('./generated/constants')
window.maps = require('./generated/maps')

window.Bus = createEventBus()
window.$ = window.jQuery = $
require('bootstrap')
require('slick-carousel')
require('./configuration')

setupApp()

require('./landing-pages/site')

if (!Spark.hideNav) {
  require('./landing-pages/stripe-menu')
}
